var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{ref:"status-confirmation-modal",attrs:{"id":"status-confirmation-modal","centered":"","hide-header":"","hide-footer":"","size":"md"},on:{"hide":function($event){return _vm.$emit('onClose')}}},[_c('h3',{staticClass:"text-center font-weight-semi-bold text-color-1 mb-9"},[_c('inline-svg',{attrs:{"src":require(`@/assets/icons/library-${
          _vm.content.status === 'unlisted' ? 'relist' : 'unlist'
        }.svg`),"height":30,"width":30}}),_vm._v(" "+_vm._s(_vm.content.status === "unlisted" ? _vm.$t("libraryContent.relistModalHeading") : _vm.$t("libraryContent.unlistModalHeading"))+" ")],1),_c('div',{staticClass:"p-4"},[_c('h4',{staticClass:"text-center px-3"},[_vm._v(" "+_vm._s(_vm.content.status === "unlisted" ? _vm.$t("libraryContent.relistModalDescription") : _vm.$t("libraryContent.unlistModalDescription"))+" ")]),_c('div',{staticClass:"d-flex justify-content-between align-items-center status-modal-item-details-wrapper"},[_c('div',{staticClass:"icon-wrapper"},[_c('inline-svg',{attrs:{"src":require('@/assets/icons/library-item.svg'),"height":40,"width":40}})],1),_c('div',{staticClass:"item-name"},[_c('p',[_vm._v(_vm._s(_vm.content.title))])]),_c('h3',[_vm._v(_vm._s(_vm.content.price)+" "+_vm._s(_vm.platformCurrency))])]),(_vm.content.status === 'listed')?_c('p',{staticClass:"text-center"},[_c('span',{staticClass:"underline"},[_vm._v(_vm._s(_vm.$t("libraryContent.unlistModalNoteHeading"))+": ")]),_vm._v(_vm._s(_vm.$t("libraryContent.unlistModalNoteDescription"))+" ")]):_vm._e(),_c('b-row',{staticClass:"mt-5"},[(_vm.content.status === 'unlisted')?[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"id":"checkbox-1","name":"checkbox-1","unchecked-value":false},model:{value:(_vm.termsConditionsCheck),callback:function ($$v) {_vm.termsConditionsCheck=$$v},expression:"termsConditionsCheck"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t(
                  'libraryContent.libraryContentStatusModalTermAndConditions'
                )
              )}})])],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"id":"checkbox-2","name":"checkbox-2","unchecked-value":false},model:{value:(_vm.distributionAllowed),callback:function ($$v) {_vm.distributionAllowed=$$v},expression:"distributionAllowed"}},[_vm._v(" "+_vm._s(_vm.$t("libraryContent.libraryContentStatusModalDistributionRights"))+" ")])],1)]:_vm._e(),_c('b-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"btn main-btn main-btn-sm mr-4",attrs:{"variant":"primary","disabled":_vm.hasCheckedAndArgeed || _vm.processing},on:{"click":_vm.handleSubmit}},[(_vm.processing)?_c('b-spinner',{staticClass:"mr-2",attrs:{"variant":"light","small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.content.status === "unlisted" ? _vm.$t("libraryContent.relistModalSubmitButtonText") : _vm.$t("libraryContent.unlistModalSubmitButtonText"))+" ")],1),_c('b-button',{staticClass:"btn main-btn main-btn-sm",on:{"click":function($event){return _vm.$emit('onClose')}}},[_vm._v(" "+_vm._s(_vm.$t("libraryContent.unlistModalCancelButtonText"))+" ")])],1)])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }