
import Vue from "vue";
import LibraryContent from "@/components/Library/LibraryContent.vue";
import EditContentModal from "@/components/Library/EditContentModal.vue";
import ContentStatusModal from "@/components/Library/ContentStatusModal.vue";
import ViewContentModal from "@/components/Library/ViewContentModal.vue";
import ContentStatisticsModal from "@/components/Library/ContentStatisticsModal.vue";
import ItemPurchasedModal from "@/components/Library/ItemPurchasedModal.vue";
import LibraryContentsSkeleton from "@/components/Library/LibraryContentsSkeleton.vue";
import { mapState } from "vuex";
import draggable from "vuedraggable";
import Api from "@/services/Api";

interface LibraryState {
  loading: boolean;
  next_page: number | null;
  libraryContents: [];
}

export default Vue.extend({
  name: "LibraryContents",
  components: {
    LibraryContent,
    EditContentModal,
    ContentStatusModal,
    ViewContentModal,
    ContentStatisticsModal,
    ItemPurchasedModal,
    LibraryContentsSkeleton,
    draggable
  },
  props: ["isMentor"],
  data() {
    return {
      selectedContent: null as null | any,
      statusUpdating: false as boolean,
      isStatusModalOpen: false as boolean,
      isStatsModalOpen: false as boolean,
      isEditModalOpen: false as boolean,
      isViewModalOpen: false as boolean,
      isPurchasedModalOpen: false as boolean,
      isPurchasing: false as boolean
    };
  },
  computed: {
    ...mapState<LibraryState>("library_content", [
      "loading",
      "next_page",
      "libraryContents"
    ]),
    previewImage(): string | null {
      if (this.selectedContent && "cover_image" in this.selectedContent) {
        return (this.selectedContent as { cover_image: string }).cover_image;
      }
      return null;
    }
  },
  methods: {
    openEditModal({ content, name }: { content: any; name: string }) {
      this.isEditModalOpen = true;
      const modifiedContent = { ...this.modifyDataFor(content) };
      this.openModal(modifiedContent, name);
    },
    openStatusModal({ content, name }: { content: any; name: string }) {
      this.isStatusModalOpen = true;
      this.openModal(content, name);
    },
    openStatsModal({ content, name }: { content: any; name: string }) {
      this.isStatsModalOpen = true;
      this.openModal(content, name);
    },
    openPurchasedModal({ content, name }: { content: any; name: string }) {
      this.isPurchasedModalOpen = true;
      this.openModal(content, name);
    },
    openViewModal({
      content,
      name,
      purchase
    }: {
      content: any;
      name: string;
      purchase: boolean;
    }) {
      this.isViewModalOpen = true;
      this.isPurchasing = purchase;
      this.openModal(content, name);
    },
    openModal(content: any, name: string) {
      this.selectedContent = content;
      this.$nextTick(() => this.$bvModal.show(name));
    },
    modifyDataFor(content: any) {
      const [tens, units] = content.price.toString().split(".");
      content.price_tens = tens;
      content.price_unit = units ?? "0";
      content.content_src = content.content_source;
      if (content.content_src === "file") content.file_path = content.source;
      else content.file_url = content.source;

      return content;
    },
    async loadMore() {
      await this.$store.dispatch("library_content/GET_NEXT_PAGE_DATA");
    },
    closeViewContentModal(isPurchase: boolean) {
      this.isViewModalOpen = false;
      if (isPurchase) {
        this.isPurchasedModalOpen = true;
        this.openModal(this.selectedContent, "item-purchased-modal");
      } else {
        this.selectedContent = null;
        this.isPurchasing = false;
      }
    },
    async closePurchasedModal() {
      this.selectedContent = null;
      this.isPurchasedModalOpen = false;
      await this.$store.dispatch(
        "library_content/GET_ALL_CONTENT_BY_MENTOR",
        this.$route.params.id
      );
    },
    async moveItem(item: any) {
      try {
        const { oldIndex, newIndex } = item;
        // @ts-ignore
        const oldItem: any = this.libraryContents.at(oldIndex);
        // @ts-ignore
        const newItem: any = this.libraryContents.at(newIndex);

        if (!oldItem && !newItem) return false;

        const data = [
          {
            id: newItem.id,
            index: newIndex + 1
          },
          {
            id: oldItem.id,
            index: oldIndex + 1
          }
        ];

        await Api().post("/library-content/update_sorting", { items: data });
      } catch (errors) {
        console.error(errors);
      }
    }
  }
});
