<template>
  <b-modal
    v-if="isContentDefined"
    id="item-purchased-modal"
    ref="item-purchased-modal"
    centered
    hide-footer
    hide-header
    size="md"
    @hide="$emit('onClose')"
  >
    <h3 class="text-center font-weight-semi-bold text-color-1 mb-9">
      <inline-svg
        :src="require(`@/assets/icons/library-purchased.svg`)"
        :height="30"
        :width="30"
      ></inline-svg>
      {{ $t("libraryContent.purchasedContentModalHeading") }}
    </h3>
    <b-container fluid>
      <b-row>
        <b-col sm="12" md="4">
          <b-img
            ref="cover_image"
            :src="content.cover_image"
            height="100"
            class="cursor-pointer border-radius-5 img-contain h-auto w-100"
          />
        </b-col>
        <b-col sm="12" md="8" class="library-content">
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="4"
            content-cols-sm="8"
            content-cols-lg="8"
            :label="`${$t('content.itemTitle')}:`"
            class="align-items-center position-relative"
          >
            <p class="font-weight-bold">{{ content.title }}</p>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="4"
            content-cols-sm="8"
            content-cols-lg="8"
            :label="`${$t('content.contentDistributor')}:`"
            class="align-items-center position-relative"
          >
            <p class="">
              <a href="">
                {{ distributorName }}
              </a>
            </p>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="4"
            content-cols-sm="8"
            content-cols-lg="8"
            :label="`${$t('content.contentType')}:`"
            class="align-items-center position-relative"
          >
            <p class="">
              <inline-svg
                :src="content_type.icon"
                :height="20"
                :width="20"
              ></inline-svg>
              <span class="d-inline-block ml-3">{{ content_type.label }}</span>
            </p>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="4"
            content-cols-sm="8"
            content-cols-lg="8"
            label="Amount Paid:"
            class="align-items-center position-relative"
          >
            <p class="">{{ content.price }} {{ platformCurrency }}</p>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="w-100 d-flex justify-content-center">
            <b-button
              class="btn main-btn main-btn-sm mr-4"
              variant="primary"
              @click="downloadItem"
            >
              {{
                content.content_type === "file"
                  ? $t("libraryContent.libraryContentDownloadBtn")
                  : $t("libraryContent.libraryContentViewBtn")
              }}
            </b-button>
            <b-button
              class="btn main-btn main-btn-sm"
              @click="$bvModal.hide('item-purchased-modal')"
            >
              {{ $t("sessions.viewContentModalCancelBtn") }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "ItemPurchasedModal",
  props: {
    content: Object
  },
  data() {
    return {
      content_types: [
        {
          label: "Book",
          value: "book",
          icon: require("@/assets/icons/library-book.svg")
        },
        {
          label: "Multimedia",
          value: "media",
          icon: require("@/assets/icons/library-media.svg")
        },
        {
          label: "Document",
          value: "document",
          icon: require("@/assets/icons/library-docs.svg")
        },
        {
          label: "Other",
          value: "other",
          icon: require("@/assets/icons/library-others.svg")
        }
      ],
      content_type: {}
    };
  },
  computed: {
    distributorName() {
      return this.content.user.first_name + " " + this.content.user.last_name;
    },
    isContentDefined() {
      return this.content !== null && typeof this.content === "object";
    }
  },
  mounted() {
    if (this.content) {
      this.content_type = this.content_types.filter(
        content => content.value === this.content.content_type
      )[0];
    }
  },
  methods: {
    downloadItem() {
      if (this.content.content_type === "file") {
        const link = document.createElement("a");
        link.href = this.content.source;
        link.download = this.content.title;
        link.click();
      } else {
        window.open(this.content.source);
      }
    }
  }
});
</script>
