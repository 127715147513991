
import Vue from "vue";

export default Vue.extend({
  name: "Pagination",
  props: ["data", "dispatch", "meta"],
  data() {
    return {
      currentPage: 1
    };
  },
  methods: {
    setPage(page: any) {
      this.currentPage = page;
    },
    paginate(page: number) {
      this.$store
        .dispatch(`paginate/PAGINATE`, {
          dispatch: this.dispatch,
          page,
          ...this.meta
        })
        .then(() => {
          this.$emit("page", page);
        });
    }
  }
});
