<template>
  <b-container fluid>
    <b-row
      :class="{
        'p-3 border content-wrapper': true,
        [getContentStatusBg]: true
      }"
    >
      <b-col sm="12" md="2" class="cover-wrapper px-0">
        <img
          :src="content.cover_image"
          alt="cover-image"
          @click="$emit('onPreview')"
        />
      </b-col>
      <b-col sm="12" md="10" class="relative px-0">
        <div
          class="pb-2"
          style="border-bottom: 1px solid #DDE7FE; cursor: pointer;"
          @click="e => openViewContentModal(false)"
        >
          <div class="content-description">
            <p class="font-sm">{{ content.description }}</p>
          </div>
          <div
            class="d-flex align-items-center justify-content-between my-3 content-details-tabs"
          >
            <div class="tab-item">
              <inline-svg
                :src="require('@/assets/icons/library-calendar-grey.svg')"
                :height="20"
                :width="20"
              ></inline-svg>
              <span class="d-inline-block ml-2"
                >{{ $t("libraryContent.libraryContentListDateLabel") }}:</span
              >
              <span class="d-inline-block ml-2">{{ formattedDate }}</span>
            </div>
            <div v-if="isMentor" class="tab-item">
              <inline-svg
                :src="require('@/assets/icons/library-cart-grey.svg')"
                :height="25"
                :width="25"
              ></inline-svg>
              <span class="d-inline-block ml-2"
                >{{
                  $t("libraryContent.libraryContentListPurchasesLabel")
                }}:</span
              >
              <span class="d-inline-block ml-2">{{
                content.bookings_count
              }}</span>
            </div>
            <div class="tab-item">
              <inline-svg
                :src="require('@/assets/icons/library-content-grey.svg')"
                :height="20"
                :width="20"
              ></inline-svg>
              <span class="d-inline-block ml-2"
                >{{
                  $t("libraryContent.libraryContentListContentTypeLabel")
                }}:</span
              >
              <span
                class="d-inline-block ml-2"
                style="text-transform: capitalize;"
                >{{ content.content_type }}</span
              >
            </div>
            <div class="tab-item">
              <inline-svg
                :src="require('@/assets/icons/library-dollar-grey.svg')"
                :height="22"
                :width="22"
              ></inline-svg>
              <span class="d-inline-block ml-2"
                >{{ $t("libraryContent.libraryContentListPriceLabel") }}:</span
              >
              <span class="d-inline-block ml-2"
                >{{ content.price }} {{ platformCurrency }}</span
              >
            </div>
          </div>
        </div>
        <div class="actions-wrapper mt-3">
          <b-button
            variant="primary"
            class="btn-library"
            @click="e => gotoItemPage(content.id)"
          >
            <inline-svg
              :src="require('@/assets/icons/library-eye.svg')"
              :height="22"
              :width="22"
              class="d-inline-block mr-1"
            >
            </inline-svg>
            <span>{{ $t("libraryContent.libraryContentListDetailsBtn") }}</span>
          </b-button>
          <template v-if="isMentor">
            <b-button
              variant="primary"
              class="btn-library"
              @click="openContentStatisticsModal"
            >
              <inline-svg
                :src="require('@/assets/icons/library-stats.svg')"
                :height="14"
                :width="14"
                class="d-inline-block"
              >
              </inline-svg>
              <span>{{ $t("libraryContent.libraryContentListStatsBtn") }}</span>
            </b-button>
            <b-button
              variant="primary"
              class="btn-library"
              @click="openContentEditModal"
            >
              <inline-svg
                :src="require('@/assets/icons/edit-outlined-white.svg')"
                :height="14"
                :width="14"
                class="d-inline-block"
              >
              </inline-svg>
              <span>{{ $t("libraryContent.libraryContentListEditBtn") }}</span>
            </b-button>
            <b-button
              variant="primary"
              class="btn-library"
              @click="openStatusUpdateModal"
            >
              <inline-svg
                :src="
                  require(`@/assets/icons/library-${
                    content.status === 'listed' ? 'unlisted' : 'relisted'
                  }-item-white.svg`)
                "
                :height="16"
                :width="16"
                class="d-inline-block"
              >
              </inline-svg>
              <span class="font-weight-bold">
                {{
                  content.status === "listed"
                    ? $t("libraryContent.libraryContentListUnlistBtn")
                    : $t("libraryContent.libraryContentListRelistBtn")
                }}
              </span>
            </b-button>
          </template>
          <template v-else>
            <b-button
              variant="primary"
              class="btn-library"
              @click="e => openViewContentModal(true)"
            >
              <inline-svg
                :src="
                  require(`@/assets/icons/library-${
                    !content.is_purchased ? 'cart-white' : 'purchased-tick'
                  }.svg`)
                "
                :height="content.is_purchased ? 14 : 20"
                :width="content.is_purchased ? 14 : 20"
                class="d-inline-block mr-1"
              >
              </inline-svg>
              <span>{{
                content.is_purchased
                  ? $t("libraryContent.libraryContentListPurchasedBtn")
                  : $t("libraryContent.libraryContentListPurchaseBtn")
              }}</span>
            </b-button>
          </template>
        </div>
        <div class="dragger p-3">
          <b-button
            v-if="isMyLibraryPage"
            class="btn-draggable bg-white border-0"
          >
            <inline-svg
              :src="require('@/assets/icons/library-hamburger.svg')"
              :height="25"
              :width="25"
            ></inline-svg>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "LibraryContent",
  props: ["content", "isMentor"],
  data() {
    return {
      processing: false
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    }),
    formattedDate() {
      return moment(this.content.created_at).format("Do MMMM, YYYY");
    },
    getContentStatusBg() {
      if (this.isMentor) {
        return this.content.status === "unlisted" ? "bg-unlisted" : "bg-white";
      } else {
        return this.content.is_purchased && this.$route.name != "MyLibraryPage"
          ? "bg-purchased"
          : "bg-white";
      }
    },
    isMyLibraryPage() {
      return this.$route.matched.some(({ path }) =>
        path.startsWith("/my-library")
      );
    }
  },
  created() {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  methods: {
    openContentEditModal() {
      if (!this.isMentor) return;
      this.$emit("onEdit", {
        content: this.content,
        name: "edit-content-modal"
      });
    },
    openStatusUpdateModal() {
      if (!this.isMentor) return;
      this.$emit("onStatusUpdate", {
        content: this.content,
        name: "status-confirmation-modal"
      });
    },
    openContentStatisticsModal() {
      if (!this.isMentor) return;
      this.$emit("openStats", {
        content: this.content,
        name: "content-statistics-modal"
      });
    },
    openViewContentModal(isPurchasing) {
      if (isPurchasing && this.content.is_purchased) return false;
      this.$emit("onView", {
        content: this.content,
        name: "view-content-modal",
        purchase: isPurchasing
      });
    },
    gotoItemPage(id) {
      this.$router.push({ name: "LibraryItemPage", params: { id: id } });
    }
  }
});
</script>

<style>
.bg-purchased {
  background-color: #f3fff6;
}

.bg-unlisted {
  background-color: #ebeff8;
}
</style>
