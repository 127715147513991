
import Vue from "vue";
import PaymentModal from "@/components/Modals/PaymentModal.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ContentViewModal",
  components: {
    PaymentModal
  },
  props: {
    content: Object,
    isMentor: Boolean,
    purchase: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content_types: [
        {
          label: "Book",
          value: "book",
          icon: require("@/assets/icons/library-book.svg")
        },
        {
          label: "Multimedia",
          value: "media",
          icon: require("@/assets/icons/library-media.svg")
        },
        {
          label: "Document",
          value: "document",
          icon: require("@/assets/icons/library-docs.svg")
        },
        {
          label: "Other",
          value: "other",
          icon: require("@/assets/icons/library-others.svg")
        }
      ],
      content_type: {} as any,
      coupon_code: "",
      paymentMethod: "",
      purchaseItem: this.content,
      isLibraryContent: true
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    }),
    distributorName(): string {
      return this.content.user.first_name + " " + this.content.user.last_name;
    },
    isContentDefined(): boolean {
      return this.content !== null && typeof this.content === "object";
    },
    formattedPrice(): string {
      return this.content.price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  created() {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  mounted() {
    if (this.content) {
      this.content_type = this.content_types.filter(
        content => content.value === this.content.content_type
      )[0];
    }
  },
  methods: {
    openEditModal() {
      if (!this.isMentor) return;
      this.$bvModal.hide("view-content-modal");
      this.$emit("onEdit");
    },
    closeModal() {
      this.$emit("onClose");
    },
    getItem() {
      this.$bvModal.show("payment");
    },
    paymentComplete() {
      this.$emit("onClose", true);
    },
    downloadItem() {
      if (this.content.content_type === "file") {
        const link = document.createElement("a");
        link.href = this.content.source;
        link.download = this.content.title;
        link.click();
      } else {
        window.open(this.content.source);
      }
    }
  }
});
