<template>
  <b-modal
    id="content-statistics-modal"
    ref="content-statistics-modal"
    centered
    hide-footer
    hide-header
    size="lg"
    @hide="$emit('onClose')"
  >
    <h3 class="text-center font-weight-semi-bold text-color-1">
      {{ $t("libraryContent.contentStatisticsModalHeading") }}
    </h3>
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <p
            class="p-3 text-center"
            style="color: #4E5D78; font-weight: 400; font-size: 14px;"
          >
            {{ content.title }}
          </p>
        </b-col>
        <b-col cols="12" class="mt-5">
          <div class="d-flex justify-content-between align-items-center">
            <p class="p-3 text-center content-statistics-item-detail">
              <inline-svg
                :src="require('@/assets/icons/library-calendar-grey.svg')"
                :height="18"
                :width="18"
              >
              </inline-svg>
              <span
                >{{ $t("libraryContent.libraryContentDateAddedLabel") }}:</span
              >
              <span> {{ formattedDate }} </span>
            </p>
            <p class="p-3 text-center content-statistics-item-detail">
              <inline-svg
                :src="require('@/assets/icons/library-cart-grey.svg')"
                :height="18"
                :width="18"
              ></inline-svg>
              <span
                >{{
                  $t("libraryContent.libraryContentTotalPurchasesLabel")
                }}:</span
              >
              <span>
                {{ content.bookings_count }}
              </span>
            </p>
            <p class="p-3 text-center content-statistics-item-detail">
              <inline-svg
                :src="require('@/assets/icons/library-dollar-grey.svg')"
                :height="18"
                :width="18"
              ></inline-svg>
              <span
                >{{
                  $t("libraryContent.libraryContentTotalPurchasesLabel")
                }}:</span
              >
              <span> {{ total }} {{ platformCurrency }} </span>
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-5 justify-content-between stats-wrapper h-100">
        <b-col cols="6" class="h-100">
          <b-form-group class="search-wrapper mb-0 h-100">
            <div
              class="d-flex justify-content-between align-items-center border px-2 py-2 rounded"
            >
              <b-form-input
                v-model.lazy="filters.search"
                :placeholder="
                  $t('libraryContent.libraryContentTSearchPlaceholder')
                "
                class="border-0 search-input"
              />
              <b-button style="background-color: transparent; border: 0;">
                <inline-svg
                  :src="require('@/assets/icons/library-search-icon-grey.svg')"
                  height="20"
                  width="20"
                >
                </inline-svg>
              </b-button>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <div class="d-flex w-100 justify-content-end h-100">
            <b-button class="bg-transparent border-0">
              <inline-svg
                :src="require('@/assets/icons/excel-download.svg')"
                height="40"
                width="40"
              ></inline-svg>
            </b-button>
            <b-dropdown ref="filter_dropdown">
              <template #button-content>
                <inline-svg
                  :src="require('@/assets/icons/library-filter.svg')"
                  height="21"
                  width="21"
                ></inline-svg>
                <span>{{ $t("libraryContent.libraryContentOrderby") }}</span>
                <inline-svg
                  :src="require('@/assets/icons/chevron-down.svg')"
                  height="10"
                  width="10"
                ></inline-svg>
              </template>
              <b-dropdown-item
                href="javascript:void(0)"
                :active="filters.orderBy === 'date'"
                @click="setOrderBy('date')"
              >
                <inline-svg
                  :src="require('@/assets/icons/library-calendar.svg')"
                  height="21"
                  width="21"
                ></inline-svg>
                <span>{{
                  $t("libraryContent.libraryContentDateofPurchase")
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                href="javascript:void(0)"
                :active="filters.orderBy === 'name'"
                @click="setOrderBy('name')"
              >
                <inline-svg
                  :src="require('@/assets/icons/library-book.svg')"
                  height="21"
                  width="21"
                ></inline-svg>
                <span>{{ $t("libraryContent.libraryContentName") }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                href="javascript:void(0)"
                :active="filters.orderBy === 'amount'"
                @click="setOrderBy('amount')"
              >
                <inline-svg
                  :src="require('@/assets/icons/library-price.svg')"
                  height="21"
                  width="21"
                ></inline-svg>
                <span>{{ $t("libraryContent.libraryContentAmountPaid") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="stats-list mt-5">
      <b-row class="mt-5">
        <template v-if="!loading">
          <template v-if="stats.length > 0">
            <b-col
              v-for="(stat, index) in stats"
              :key="index"
              cols="12"
              class="my-3"
            >
              <div
                class="stats-item d-flex justify-content-between align-items-center border rounded p-2"
                style="box-shadow: 12px 10px 14px 0px #8A94A60D;"
              >
                <!-- user -->
                <div class="user-wrapper d-flex align-items-center">
                  <b-img
                    :src="stat.user.profile_link"
                    alt="profile_pic"
                    height="50"
                    width="50"
                    style="border-radius: 5px;"
                  />
                  <p class="font-semi-bold ml-5">
                    {{ stat.user.first_name + " " + stat.user.last_name }}
                  </p>
                </div>
                <!-- amont -->
                <div
                  class="amount-wrapper d-flex justify-content-between align-items-center"
                >
                  <div
                    class="icon bg-light rounded"
                    style="border-radius: 60px; width: auto; padding: 3px; margin-left: 10px;"
                  >
                    <inline-svg
                      :src="require('@/assets/icons/library-price.svg')"
                      height="20"
                      width="20"
                    ></inline-svg>
                  </div>
                  <p class="ml-3">{{ stat.price }} {{ platformCurrency }}</p>
                </div>
                <!-- actions -->
                <div class="action-wrapper">
                  <b-button variant="primary" class="px-2 ml-2">
                    <inline-svg
                      :src="
                        require('@/assets/icons/library-stats-action-3.svg')
                      "
                      :height="25"
                      :width="25"
                    >
                    </inline-svg>
                  </b-button>
                </div>
              </div>
            </b-col>
          </template>
          <template v-else>
            <b-col
              style="box-shadow: 1px 1px 8px #f3f3f3;
                  text-align: center;
                  font-size: 20px;
                  background-color: white;
                  padding: 5  px;
                  border-radius: 5px;
                  padding: 15px;
              "
            >
              <h4 class="mb-0">
                {{ $t("libraryContent.libraryContentNoPurchases") }}
              </h4>
            </b-col>
          </template>
        </template>
        <template v-else>
          <ContentStatisticsSkeleton />
        </template>
      </b-row>
      <b-row class="mt-5">
        <b-col class="d-flex justify-content-center">
          <Pagination
            v-if="paginated.data && paginated.data.length > 0"
            ref="pagination"
            :data="paginated"
            dispatch="library_content/GET_CONTENT_STATISTICS"
            :meta="{
              params: {
                page: currentPage,
                ...filters
              },
              id: content.id
            }"
            @page="
              page => {
                currentPage = page;
                getContent();
              }
            "
          />
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import Pagination from "@/components/Pagination.vue";
import ContentStatisticsSkeleton from "@/components/Library/ContentStatisticsSkeleton.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ContentStatisticsModal",
  components: {
    Pagination,
    ContentStatisticsSkeleton
  },
  props: {
    content: Object
  },
  data() {
    return {
      stats: [],
      total: 0,
      filters: {
        search: null,
        order_by: null
      },
      paginated: 1,
      currentPage: 1,
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    }),
    formattedDate() {
      return moment(this.content.created_at).format("Do MMMM, YYYY");
    }
  },

  watch: {
    filters: {
      deep: true,
      async handler() {
        await this.getContent();
      }
    }
  },
  async mounted() {
    await this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
    await this.getContent();
  },
  methods: {
    async getContent() {
      try {
        const { purchases, total } = await this.$store.dispatch(
          "library_content/GET_CONTENT_STATISTICS",
          {
            page: this.currentPage,
            id: this.content.id,
            ...this.filters
          }
        );
        this.paginated = purchases;
        this.stats = purchases.data;
        this.total = total;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    setOrderBy(type) {
      this.filters.order_by = type;
    },
    logger(content) {
      console.log(content);
    }
  }
});
</script>

<style>
.user-wrapper {
  flex-basis: 160px;
}
</style>
