var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-container',{staticClass:"bg-light p-5 library-contents-wrapper"},[(!_vm.loading)?[(_vm.libraryContents.length > 0)?[_c('draggable',{attrs:{"list":_vm.libraryContents,"handle":".btn-draggable","options":{ animation: 300 }},on:{"end":_vm.moveItem}},[_c('transition-group',[_vm._l((_vm.libraryContents),function(content,index){return [_c('LibraryContent',{key:`${content.id} - ${index}`,attrs:{"content":content,"statusUpdating":_vm.statusUpdating,"is-mentor":_vm.isMentor},on:{"onEdit":_vm.openEditModal,"onStatusUpdate":_vm.openStatusModal,"onPreview":function($event){return _vm.openModal(content, 'content-preview-modal')},"onView":_vm.openViewModal,"openStats":_vm.openStatsModal}})]})],2)],1),_c('div',{staticClass:"load-more-wrapper d-flex justify-content-center my-5"},[(_vm.next_page)?_c('b-button',{staticClass:"btn btn-primary px-5 py-2",on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.loading ? "Loading..." : "Load More")+" ")]):_vm._e()],1)]:[_c('h2',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("libraryContent.libraryContentListNoContent"))+" ")])]]:[_c('LibraryContentsSkeleton')],(_vm.isMentor && _vm.isEditModalOpen)?_c('EditContentModal',{attrs:{"library-content":_vm.selectedContent},on:{"onClose":() => {
        _vm.selectedContent = null;
        _vm.isEditModalOpen = false;
      }}}):_vm._e(),(_vm.isMentor && _vm.isStatusModalOpen)?_c('ContentStatusModal',{attrs:{"content":_vm.selectedContent},on:{"onClose":() => {
        _vm.selectedContent = null;
        _vm.isStatusModalOpen = false;
      }}}):_vm._e(),(_vm.isViewModalOpen)?_c('ViewContentModal',{attrs:{"content":_vm.selectedContent,"is-mentor":_vm.isMentor,"purchase":_vm.isPurchasing},on:{"onClose":_vm.closeViewContentModal,"onEdit":() => _vm.$bvModal.show('edit-content-modal')}}):_vm._e(),(_vm.isMentor && _vm.isStatsModalOpen)?_c('ContentStatisticsModal',{attrs:{"content":_vm.selectedContent},on:{"onClose":() => {
        _vm.selectedContent = null;
        _vm.isStatsModalOpen = false;
      }}}):_vm._e(),(_vm.isPurchasedModalOpen)?_c('ItemPurchasedModal',{attrs:{"content":_vm.selectedContent},on:{"onClose":_vm.closePurchasedModal}}):_vm._e(),_c('b-modal',{ref:"content-preview-modal",attrs:{"id":"content-preview-modal","centered":"","hide-footer":"","hide-header":"","size":"sm"}},[_c('img',{staticClass:"d-block mx-auto",attrs:{"src":_vm.previewImage,"alt":"preview-img","width":"300","height":"auto"}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }