
import Vue from "vue";
import { mapGetters } from "vuex";
import ClipperModal from "@/components/ClipperModal.vue";

interface Content {
  id: number;
  cover_image: string | File;
  title: string;
  description: string;
  price_tens: number | null;
  price_unit: number | null;
  content_type: string;
  content_src: "file" | "url";
  file_path: string | File;
  file_url: string;
}

export default Vue.extend({
  name: "EditContentModal",
  components: {
    ClipperModal
  },
  props: {
    libraryContent: Object
  },
  data() {
    return {
      content: {
        id: 0,
        cover_image: "",
        title: "",
        description: "",
        price_tens: null,
        price_unit: null,
        content_type: "",
        content_src: "file",
        file_path: "",
        file_url: ""
      } as Content,
      errors: {} as any,
      title_word_limit: 100 as number,
      description_word_limit: 200 as number,
      processing: false as boolean,
      defaultCover: require("@/assets/images/default-content-cover.png"),
      cover_blob_url: "",
      content_types: [
        {
          label: "Book",
          value: "book",
          icon: require("@/assets/icons/library-book.svg")
        },
        {
          label: "Multimedia",
          value: "media",
          icon: require("@/assets/icons/library-media.svg")
        },
        {
          label: "Document",
          value: "document",
          icon: require("@/assets/icons/library-docs.svg")
        },
        {
          label: "Other",
          value: "other",
          icon: require("@/assets/icons/library-others.svg")
        }
      ],
      termsConditionsCheck: false as boolean,
      distributionAllowed: false as boolean
    };
  },
  computed: {
    remainingTitleChar(): number {
      return this.title_word_limit - this.content.title.length;
    },
    remainingDescChar(): number {
      return this.description_word_limit - this.content.description.length;
    },
    canEditNow(): boolean {
      return !this.distributionAllowed || !this.termsConditionsCheck;
    },
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    })
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  mounted() {
    this.content = this.libraryContent;
    this.defaultCover = this.libraryContent.cover_image;
  },
  methods: {
    async handleLibraryEdit() {
      try {
        this.processing = true;
        this.errors = {};
        const data = this.buildData();
        await this.$store.dispatch("library_content/UPDATE_LIBRARY_CONTENT", {
          id: this.content.id,
          data
        });
        this.processing = false;
        this.$bvModal.hide("edit-content-modal");
      } catch (error) {
        this.processing = false;
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors;
        } else {
          console.error("An error occurred:", error.message);
        }
      }
    },
    handleDocUpload(event: Event): void {
      const fileInput = event.target as HTMLInputElement;
      const filePathElement = this.$refs.file_path as HTMLSpanElement;
      const file = fileInput.files?.[0] as File | undefined;

      if (file) {
        try {
          if (filePathElement) {
            filePathElement.textContent = file.name;
          }
          this.content.file_path = file;
        } catch (error) {
          console.error("Error processing file:", error);
        }
      } else {
        console.error("No file selected.");
      }
    },
    handleCoverUpload(event: Event): void {
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0] as File | undefined;
      if (file) {
        try {
          this.cover_blob_url = URL.createObjectURL(file);
          this.$bvModal.show("clipper");
        } catch (error) {
          console.error("Error creating object URL:", error);
        }
      } else {
        console.error("No file selected.");
      }
    },
    setTempFile({ url, temp }: any) {
      const imageElement = this.$refs.cover_image as HTMLImageElement;
      if (imageElement) imageElement.src = url;
      this.content.cover_image = temp;
    },
    buildData(): FormData {
      const formData = new FormData();
      for (const key in this.content) {
        const value = this.content[key as keyof Content];
        formData.append(key, value as string | Blob);
      }
      formData.append("price", this.mergedPrice().toString());
      formData.append("_method", "PUT");
      return formData;
    },
    hasError(type: string): any {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    mergedPrice() {
      const { price_tens, price_unit } = this.content;
      if (price_tens !== null && price_unit !== null) {
        return parseFloat(`${price_tens}.${price_unit}`);
      } else if (price_tens !== null) {
        return price_tens;
      } else if (price_unit !== null) {
        return parseFloat(`0.${price_unit}`);
      } else {
        return 0;
      }
    },
    resetForm() {
      this.content.id = 0;
      this.content.cover_image = "";
      this.content.title = "";
      this.content.description = "";
      this.content.price_tens = null;
      this.content.price_unit = null;
      this.content.content_type = "";
      this.content.content_src = "file";
      this.content.file_path = "";
      this.content.file_url = "";

      this.defaultCover = require("@/assets/images/default-content-cover.png");
    },
    closeModal() {
      this.resetForm();
      this.$emit("onClose");
    }
  }
});
