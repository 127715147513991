
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ContentStatusModal",
  props: {
    content: Object
  },
  data() {
    return {
      processing: false as boolean,
      termsConditionsCheck: false as boolean,
      distributionAllowed: false as boolean
    };
  },
  computed: {
    hasCheckedAndArgeed(): boolean {
      return (
        this.content.status === "unlisted" &&
        (!this.distributionAllowed || !this.termsConditionsCheck)
      );
    },
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    })
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  methods: {
    async handleSubmit() {
      try {
        this.processing = true;
        await this.$store.dispatch("library_content/UPDATE_CONTENT_STATUS", {
          id: this.content.id,
          status: this.content.status
        });
        this.processing = false;
        this.$bvModal.hide("status-confirmation-modal");
      } catch (errors) {
        this.processing = false;
        console.error(errors);
      }
    }
  }
});
